export function isColorDark(accentColor: string) {
    // Remove the '#' character if present
    const hex = accentColor.replace("#", "");
  
    // Convert the hex color to RGB values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    // Calculate the brightness of the color
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
    // Return white or black based on the brightness threshold
    return brightness > 128 ? false : true;
  }