// app/components/EditorStateContext.tsx

import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useState,
} from "react";

export type EditorState = {
  styles: {
    accentColor: string;
    font: string;
    backgroundColorID: string;
  };
  aspectRatio: string;
  content: any;
  videoId: number | null;
};

type HistoryState = {
  past: EditorState[];
  present: EditorState;
  future: EditorState[];
};

type EditorStateContextType = {
  editorState: EditorState;
  setEditorState: (
    state: EditorState | ((prevState: EditorState) => EditorState)
  ) => void;
  setInitialEditorStateOnce: (state: {
    styles: { accentColor: any; font: any; backgroundColorID: any };
    aspectRatio: string;
    videoId: any;
    content: {};
  }) => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
};

const EditorStateContext = createContext<EditorStateContextType | undefined>(
  undefined
);

const initialEditorState: EditorState = {
  styles: {
    accentColor: "",
    font: "",
    backgroundColorID: "white",
  },
  aspectRatio: "horizontal",
  content: { frames: [] },
  videoId: null,
};

export function EditorStateProvider({ children }: { children: ReactNode }) {
  const [history, setHistory] = useState<HistoryState>({
    past: [],
    present: initialEditorState,
    future: [],
  });

  const setEditorState = useCallback(
    (newState: EditorState | ((prevState: EditorState) => EditorState)) => {
      setHistory(({ past, present, future }) => {
        const updatedState =
          typeof newState === "function" ? newState(present) : newState;
        return {
          past: [...past, present],
          present: updatedState,
          future: [],
        };
      });
    },
    []
  );

  const setInitialEditorStateOnce = useCallback((newState: EditorState) => {
    setHistory({
      past: [],
      present: newState,
      future: [],
    });
  }, []);

  const undo = useCallback(() => {
    setHistory(({ past, present, future }) => {
      if (past.length === 0) return { past, present, future };
      const previous = past[past.length - 1];
      return {
        past: past.slice(0, past.length - 1),
        present: previous,
        future: [present, ...future],
      };
    });
  }, []);

  const redo = useCallback(() => {
    setHistory(({ past, present, future }) => {
      if (future.length === 0) return { past, present, future };
      const next = future[0];
      return {
        past: [...past, present],
        present: next,
        future: future.slice(1),
      };
    });
  }, []);

  const value: EditorStateContextType = {
    editorState: history.present,
    setEditorState,
    setInitialEditorStateOnce,
    undo,
    redo,
    canUndo: history.past.length > 0,
    canRedo: history.future.length > 0,
  };

  return (
    <EditorStateContext.Provider value={value}>
      {children}
    </EditorStateContext.Provider>
  );
}

export function useEditorState() {
  const context = useContext(EditorStateContext);
  if (context === undefined) {
    throw new Error(
      "useEditorState must be used within an EditorStateProvider"
    );
  }
  return context;
}
