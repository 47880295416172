import React, { useState, useRef, useEffect } from "react";
import {
  Popover,
  Box,
  Button,
  Progress,
  Text,
  Blockquote,
} from "@mantine/core";
import {
  IconDatabaseSmile,
  IconInfoCircle,
  IconUpload,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useFetcher, useRouteLoaderData } from "@remix-run/react";
import { RootLoaderData } from "~/root";
import { useEditorState } from "../../AppContent/state/EditorStateContext";

interface FrameImageEditorProps {
  content: any;
  isEditable: boolean;
  handleContentChange: (newValue: string) => void;
  children: React.ReactNode;
  style?: any;
  hint?: string;
}

export function FrameImageAndVideoUploader({
  content,
  isEditable,
  handleContentChange,
  children,
  style,
  hint,
}: FrameImageEditorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const { editorState } = useEditorState();
  useEffect(() => {
    if (fetcher.data && fetcher.state === "idle") {
      handleUpload(fetcher.data);
    }
  }, [fetcher]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);
      setUploadProgress(0);

      const formData = new FormData();
      formData.append("filename", file.name);
      formData.append("contentType", file.type);
      formData.append("fileSize", file.size.toString());
      formData.append(
        "uploadType",
        content.type === "image" ? "image" : "video"
      );
      // Add the videoId to the form data

      // Use editorState.videoId here
      if (editorState.videoId) {
        formData.append("videoId", editorState.videoId.toString());
      }

      // Get the signed URL from the server
      fetcher.submit(formData, { method: "post", action: "/upload" });
    }
  };

  const handleUpload = async (data: any) => {
    if (data.success) {
      const file = fileInputRef.current?.files?.[0];
      if (file) {
        // Upload the file to R2 using the signed URL
        await fetch(data.signedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        // Simulate upload progress
        const totalSteps = 100;
        for (let i = 0; i <= totalSteps; i++) {
          setUploadProgress(i);
          await new Promise((resolve) => setTimeout(resolve, 20));
        }

        // Update the content with the public URL
        handleContentChange(data.publicUrl);
      }
    } else {
      console.error("Failed to get signed URL");
    }

    setIsUploading(false);
    setIsOpen(false); // Close the popover when upload is finished
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  let acceptFilesString = "image/*";
  if (content.type === "image") {
    acceptFilesString = "image/*";
  } else if (content.type === "video") {
    acceptFilesString = "video/*";
  }

  return (
    <Popover
      opened={!isEditable ? false : isOpen}
      onChange={setIsOpen}
      width={300}
      position="left"
      withArrow
      trapFocus
      transitionProps={{ duration: 0 }}
      offset={20}
    >
      <Popover.Target>
        <Box
          className={"frame__editable_content"}
          onClick={() => setIsOpen((o) => !o)}
          style={{ ...style }}
        >
          {/*<div className="frame__editable_content_border"></div>*/}
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        {isUploading ? (
          <>
            <Text size="sm" mb="xs">
              {t("Uploading...")}
            </Text>
            <Progress value={uploadProgress} mb="md" />
          </>
        ) : (
          <>
            {hint && (
              <Text size={"sm"} c="dimmed" mb={"xs"}>
                {hint}
              </Text>
            )}

            <Button
              leftSection={<IconUpload size="1rem" />}
              onClick={openFileDialog}
              fullWidth
            >
              {t("Upload new")}
            </Button>
          </>
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept={acceptFilesString}
          onChange={handleFileChange}
        />
      </Popover.Dropdown>
    </Popover>
  );
}
