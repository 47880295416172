import { interpolate } from "remotion";
import {easeInOutExpo} from "../../../remotionConstant";


const animation1Start = 100;
const animation1Duration = 20;
const animation1End = animation1Start + animation1Duration;

const animation2Start = 250;
const animation2Duration = 40;
const animation2End = animation2Start + animation2Duration;

const animation3Start = 400;

// Animation points for position
const position1X = -0;
const position1Y = 0;
const position1Z = 0.0;
const position2X = 0;
const position2Y = 0.0;
const position2Z = -0.05;
const position3X = -0.4;
const position3Y = 0;
const position3Z = -0.1;

// Animation points for scale
const scale1X = 1.1;
const scale1Y = 1.1;
const scale1Z = 1.1;
const scale2X = 1.24;
const scale2Y = 1.24;
const scale2Z = 1.24;
const scale3X = 1.28;
const scale3Y = 1.28;
const scale3Z = 1.28;

// Animation points for rotation
const rotation1X = 0.02;
const rotation1Y = -0.202;
const rotation1Z = 0.0;
const rotation2X = 0.05;
const rotation2Y = -0.252;
const rotation2Z = 0.0;
const rotation3X = 0.0;
const rotation3Y = 0.052;
const rotation3Z = 0.0;

export const getLaptopPositionX = (frame) => {
  if (frame < animation1Start) {
    return position1X;
  } else if (frame < animation2Start) {
    return interpolate(
      frame,
      [animation1Start, animation1End],
      [position1X, position2X],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else if (frame < animation3Start) {
    return interpolate(
      frame,
      [animation2Start, animation2End],
      [position2X, position3X],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else {
    return position3X;
  }
};

export const getLaptopPositionY = (frame) => {
  if (frame < animation1Start) {
    return position1Y;
  } else if (frame < animation2Start) {
    return interpolate(
      frame,
      [animation1Start, animation1End],
      [position1Y, position2Y],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else if (frame < animation3Start) {
    return interpolate(
      frame,
      [animation2Start, animation2End],
      [position2Y, position3Y],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else {
    return position3Y;
  }
};

export const getLaptopPositionZ = (frame) => {
  if (frame < animation1Start) {
    return position1Z;
  } else if (frame < animation2Start) {
    return interpolate(
      frame,
      [animation1Start, animation1End],
      [position1Z, position2Z],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else if (frame < animation3Start) {
    return interpolate(
      frame,
      [animation2Start, animation2End],
      [position2Z, position3Z],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else {
    return position3Z;
  }
};

export const getLaptopScaleX = (frame) => {
  if (frame < animation1Start) {
    return scale1X;
  } else if (frame < animation2Start) {
    return interpolate(
      frame,
      [animation1Start, animation1End],
      [scale1X, scale2X],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else if (frame < animation3Start) {
    return interpolate(
      frame,
      [animation2Start, animation2End],
      [scale2X, scale3X],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else {
    return scale3X;
  }
};

export const getLaptopScaleY = (frame) => {
  if (frame < animation1Start) {
    return scale1Y;
  } else if (frame < animation2Start) {
    return interpolate(
      frame,
      [animation1Start, animation1End],
      [scale1Y, scale2Y],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else if (frame < animation3Start) {
    return interpolate(
      frame,
      [animation2Start, animation2End],
      [scale2Y, scale3Y],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else {
    return scale3Y;
  }
};

export const getLaptopScaleZ = (frame) => {
  if (frame < animation1Start) {
    return scale1Z;
  } else if (frame < animation2Start) {
    return interpolate(
      frame,
      [animation1Start, animation1End],
      [scale1Z, scale2Z],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else if (frame < animation3Start) {
    return interpolate(
      frame,
      [animation2Start, animation2End],
      [scale2Z, scale3Z],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else {
    return scale3Z;
  }
};

export const getLaptopRotationX = (frame) => {
  if (frame < animation1Start) {
    return rotation1X;
  } else if (frame < animation2Start) {
    return interpolate(
      frame,
      [animation1Start, animation1End],
      [rotation1X, rotation2X],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else if (frame < animation3Start) {
    return interpolate(
      frame,
      [animation2Start, animation2End],
      [rotation2X, rotation3X],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else {
    return rotation3X;
  }
};

export const getLaptopRotationY = (frame) => {
  if (frame < animation1Start) {
    return rotation1Y;
  } else if (frame < animation2Start) {
    return interpolate(
      frame,
      [animation1Start, animation1End],
      [rotation1Y, rotation2Y],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else if (frame < animation3Start) {
    return interpolate(
      frame,
      [animation2Start, animation2End],
      [rotation2Y, rotation3Y],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else {
    return rotation3Y;
  }
};

export const getLaptopRotationZ = (frame) => {
  if (frame < animation1Start) {
    return rotation1Z;
  } else if (frame < animation2Start) {
    return interpolate(
      frame,
      [animation1Start, animation1End],
      [rotation1Z, rotation2Z],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else if (frame < animation3Start) {
    return interpolate(
      frame,
      [animation2Start, animation2End],
      [rotation2Z, rotation3Z],
      {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }
    );
  } else {
    return rotation3Z;
  }
};

export const getOpacity = (frame) => {
  const animationStartFrame = 0;
  const animationEndFrame = 50;

  return interpolate(frame, [animationStartFrame, animationEndFrame], [0, 1], {
    extrapolateLeft: "clamp",
    extrapolateRight: "clamp",
  });
};
