import { CardWithStatsIndex } from "./FramesComponents/CardWithStats/CardWithStatsIndex";
import { Frame1Index } from "./FramesComponents/Frame1/Frame1Index";
import { Frame2Index } from "./FramesComponents/Frame2/Frame2Index";
import { Frame3Index } from "./FramesComponents/Frame3/Frame3Index";
import { Frame4Index } from "./FramesComponents/Frame4/Frame4Index";
import { Frame6Index } from "./FramesComponents/Frame6/Frame6Index";
import { Frame7Index } from "./FramesComponents/Frame7/Frame7Index";
import { Frame8Index } from "./FramesComponents/Frame8/Frame8Index";
import { LogosCircle } from "./FramesComponents/LogosCircle/LogosCircleIndex";
import { TypingSuggestionSpotlight } from "./FramesComponents/TypingSuggestionSpotlight/TypingSuggestionSpotlightIndex";

export const allFrames = {
  //   Key (e.g. "featuresLeftBrowserMockupRight") is the frameId we are using in the Editor.
  //   We access allFrames when we add a component (we populate the frame content with defaultContent) and display thumbnails in the gallery.
  //   We access allFrames also in the Preview+Timeline: we display the tsx "component" there.
  intoLogoAndTitle: {
    thumbnails: {
      square: "https://public-assets.paracast.io/thumb-intro-50.mp4",
      horizontal: "https://public-assets.paracast.io/thumb-intro-50.mp4",
      vertical: "https://public-assets.paracast.io/thumb-intro-50.mp4",
    },
    defaultDurationInFrames: 80,
    defaultContent: {
      projectTitle: { type: "text", value: "Paracast.io" },
      image1: {
        type: "image",
        value: "https://public-assets.paracast.io/paracast-org_logo.png",
      },
    },
    component: Frame2Index,
  },
  featuresLeftBrowserMockupRight: {
    thumbnails: {
      square: "https://public-assets.paracast.io/featuresLeftBrowserMockupRight-50.mp4",
      horizontal: "https://public-assets.paracast.io/featuresLeftBrowserMockupRight-50.mp4",
      vertical: "https://public-assets.paracast.io/featuresLeftBrowserMockupRight-50.mp4",
    },
    defaultDurationInFrames: 400,
    defaultContent: {
      text1: { type: "text", value: "Freshen up your SaaS marketing" },
      text2: { type: "text", value: "Make awesome job" },
      projectTitle: { type: "text", value: "Paracast.io" },
      projectDomainName: { type: "text", value: "paracast.io" },
      video1: {
        type: "video",
        value: "https://temp.paracast.io/1719155134227-ai_code.mp4",
      },
      image1: {
        type: "image",
        value: "https://public-assets.paracast.io/paracast-org_logo.png",
      },
      image2: {
        type: "image",
        value: "https://public-assets.paracast.io/paracast-org_logo.png",
      },
      list1: {
        type: "list",
        value: [
          {
            text: "Make stunning videos",
          },
          {
            text: "Unlimited generations",
          },
          {
            text: "Grow your socials",
          },
          {
            text: "No editing needed",
          }
        ],
      },
    },
    component: Frame1Index,
  },
    finalLogoCtaAndTitle: {
      thumbnails: {
        square: "https://public-assets.paracast.io/finalLogoCtaAndTitle-50.mp4",
        horizontal: "https://public-assets.paracast.io/finalLogoCtaAndTitle-50.mp4",
        vertical: "https://public-assets.paracast.io/finalLogoCtaAndTitle-50.mp4",
      },
      defaultDurationInFrames: 170,
      defaultContent: {
        text1: { type: "text", value: "Try it today!" },
        projectTitle: { type: "text", value: "Paracast.io" },
        image1: {
          type: "image",
          value: "https://public-assets.paracast.io/paracast-org_logo.png",
        }
      },
      component: Frame3Index,
  },
  singleTestimonialAndAudience: {
    thumbnails: {
      square: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
      horizontal: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
      vertical: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
    },
    defaultDurationInFrames: 260,
    defaultContent: {
      text1: { type: "text", value: "Zeng" },
      text2: { type: "text", value: "🤯 Mind-blowing AI alert! Just discovered @paracast_io by @alexanderisorax - the AI magician that turns websites into stunning videos!" },
      
      image1: {
        type: "image",
        value: "https://pbs.twimg.com/profile_images/1823752353280929793/hDh1zFMZ_400x400.jpg",
      },

      text3: { type: "text", value: "Made" },
      text4: { type: "text", value: "exclusively" },
      text5: { type: "text", value: "for" },

      list1: {
        type: "list",
        value: [
          {
            text: "Startups",
          },
          {
            text: "Makers",
          },
          {
            text: "Founders",
          },
          {
            text: "Creators",
          },
          {
            text: "Marketers",
          },
        ],
      },
    },
    component: Frame4Index,
  },
  // floatingCodeCard: {
  //   thumbnails: {
  //     square: "https://your-thumbnail-url-here.mp4",
  //     horizontal: "https://your-thumbnail-url-here.mp4",
  //     vertical: "https://your-thumbnail-url-here.mp4",
  //   },
  //   defaultDurationInFrames: 150,
  //   defaultContent: {
  //     code: { 
  //       type: "text", 
  //       value: `function animateCode(progress) {
  //         const frames = 60;
  //         const step = Math.floor(progress * frames);
          
  //         for (let i = 0; i < step; i++) {
  //           renderFrame(i);
  //         }
          
  //         return "Animation complete!";
  //       }` 
  //     },
  //   },
  //   component: Frame6Index,
  // },

  // basicText: {
  //   thumbnails: {
  //     square: "https://your-thumbnail-url-here.mp4",
  //     horizontal: "https://your-thumbnail-url-here.mp4",
  //     vertical: "https://your-thumbnail-url-here.mp4",
  //   },
  //   defaultDurationInFrames: 100,
  //   defaultContent: {
  //     text1: { 
  //       type: "text", 
  //       value: "Make videos without making video"
  //     },
  //   },
  //   component: Frame7Index,
  // },
  // basicImage: {
  //   thumbnails: {
  //     square: "https://your-thumbnail-url-here.mp4",
  //     horizontal: "https://your-thumbnail-url-here.mp4",
  //     vertical: "https://your-thumbnail-url-here.mp4",
  //   },
  //   defaultDurationInFrames: 230,
  //   defaultContent: {
  //     img1: { 
  //       type: "image", 
  //       value: "https://temp.paracast.io/1730975468142-screencapture-paracast-io-2024-11-07-14_30_46.png"
  //     },
  //   },
  //   component: Frame8Index,
  // },

  // CardWithStats: {
  //   thumbnails: {
  //     square: "https://your-thumbnail-url-here.mp4",
  //     horizontal: "https://your-thumbnail-url-here.mp4",
  //     vertical: "https://your-thumbnail-url-here.mp4",
  //   },
  //   defaultDurationInFrames: 130,
  //   defaultContent: {
  //     number1: { 
  //       type: "text", 
  //       value: "153"
  //     },
  //     number2: { 
  //       type: "text", 
  //       value: "10%"
  //     },
  //     text1: { 
  //       type: "text", 
  //       value: "Total orders"
  //     },
  //     projectLogo: { 
  //       type: "image", 
  //       value: "https://public-assets.paracast.io/paracast-org_logo.png"
  //     },
  //   },
  //   component: CardWithStatsIndex,
  // },
  // TypingSuggestionSpotlight: {
  //   thumbnails: {
  //     square: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
  //     horizontal: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
  //     vertical: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
  //   },
  //   defaultDurationInFrames: 180,
  //   defaultContent: {
  //     text1: { type: "text", value: "add email integration" },
  //     img1: { 
  //       type: "image", 
  //       value: "https://temp.paracast.io/1730975468142-screencapture-paracast-io-2024-11-07-14_30_46.png"
  //     },
  //     projectLogo: {
  //       type: "image",
  //       value: "https://pbs.twimg.com/profile_images/1823752353280929793/hDh1zFMZ_400x400.jpg",
  //     },

  //     list1: {
  //       type: "list",
  //       value: [
  //         {
  //           text: "Mailerlite",
  //           secondaryText: "Connect this item",
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //         {
  //           text: "Mailgun",
  //           secondaryText: "Connect this item",
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //         {
  //           text: "Resend",
  //           secondaryText: "Connect this item",
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //         {
  //           text: "Mailchimp",
  //           secondaryText: "Connect this item",
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //         {
  //           text: "AWS SES",
  //           secondaryText: "Connect this item",
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //       ],
  //     },
  //   },
  //   component: TypingSuggestionSpotlight,
  // },
  // LogosCircle: {
  //   thumbnails: {
  //     square: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
  //     horizontal: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
  //     vertical: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
  //   },
  //   defaultDurationInFrames: 180,
  //   defaultContent: {
  //     projectLogo: {
  //       type: "image",
  //       value: "https://pbs.twimg.com/profile_images/1823752353280929793/hDh1zFMZ_400x400.jpg",
  //     },

  //     list1: {
  //       type: "list",
  //       value: [
  //         {
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //         {
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //         {
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //         {
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //         {
  //           image: "https://public-assets.paracast.io/paracast-org_logo.png",
  //         },
  //       ],
  //     },
  //   },
  //   component: LogosCircle,
  // },
};
