import React, { useMemo } from "react";
import { AbsoluteFill, Img, interpolate } from "remotion";
import { easeInOutExpo } from "../../../remotionConstant";
import {
  EditableFrameWrapper,
} from "../../../FrameContentEditors/EditableFrameWrapper";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import { IntroProjectLogo } from "./IntroProjectLogo";
import { ProjectTitle } from "./ProjectTitle";

export function Frame2Index({
  frameData,
  displayAnimations = false,
  isEditable = false, // it is passed as false to the Root, but we set also false here for protection (just in case)
  editorState,
  currentRemotionFrame = 0,
}: {
  frameData: any;
  displayAnimations?: boolean;
  isEditable?: boolean;
  editorState: any;
  currentRemotionFrame?: number;
}) {
  // const { editorState } = useEditorState();
  const content = frameData.content || {};

  const { durationInFrames } = frameData;

  const commonStyles = getCommonStyles(editorState);

  let opacity = 1;
  let translateY = 0;
  let fadeAwayOpacity = 1;
  let scale = 1;

  if (displayAnimations && !isEditable) {
    // opacity = interpolate(currentRemotionFrame, [25, 40], [0, 1]);
    // const fadeAwayDuration = 10;
    // const fadeAwayStartFrame = durationInFrames - fadeAwayDuration;
    // const fadeAwayEndFrame = durationInFrames;
    // const fadeAwayProgress = interpolate(
    //   currentRemotionFrame,
    //   [fadeAwayStartFrame, fadeAwayEndFrame],
    //   [0, 1],
    //   {
    //     extrapolateLeft: "clamp",
    //     extrapolateRight: "clamp",
    //     easing: easeInOutExpo,
    //   }
    // );
    //
    // translateY = interpolate(fadeAwayProgress, [0, 1], [0, -20]);
    // fadeAwayOpacity = interpolate(fadeAwayProgress, [0, 1], [1, 0]);
    // scale = interpolate(fadeAwayProgress, [0, 1], [1, 0.95]);
  }

  const containerStyle = useMemo(
    () => ({
      fontFamily: commonStyles.fontFamily,
      color: commonStyles.color,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    [commonStyles]
  );
  //
  // const contentStyle = useMemo(
  //   () => ({
  //     display: "flex",
  //     flexDirection: "column" as const,
  //     alignItems: "center",
  //     opacity,
  //   }),
  //   [opacity]
  // );
  //
  // const titleStyle = useMemo(
  //   () => ({
  //     fontSize: 70,
  //     fontWeight: 600,
  //     textAlign: "center" as const,
  //     marginTop: 40,
  //   }),
  //   [commonStyles]
  // );
  //
  // const logoStyle = useMemo(
  //   () => ({
  //     width: 200,
  //     height: "auto",
  //     opacity: 1,
  //   }),
  //   []
  // );
  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );
  return (
    <EditableFrameWrapper frameData={frameData} isEditable={isEditable}>
      <AbsoluteFill
        style={{
          ...containerStyle,
          backgroundColor: bgColorObject["backgroundColor"],
        }}
      >
        <IntroProjectLogo
          currentRemotionFrame={currentRemotionFrame}
          durationInFrames={durationInFrames}
          content={content}
          displayAnimations={displayAnimations}
          isEditable={isEditable}
        />
        <ProjectTitle
          currentRemotionFrame={currentRemotionFrame}
          durationInFrames={durationInFrames}
          content={content}
          displayAnimations={displayAnimations}
          isEditable={isEditable}
          commonStyles={commonStyles}
        />
      </AbsoluteFill>
    </EditableFrameWrapper>
  );
}
