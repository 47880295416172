// app/utils/styleUtils.ts

import { EditorState } from "../../AppContent/state/EditorStateContext";
import { findBackgroundColorObjectByID } from "./findBackgroundColorObjectByID";

export function getCommonStyles(editorState: EditorState) {
  if (!editorState) {
    return {
      fontFamily: 'Arial, sans-serif',
      color: '#000000',
      accentColor: '#007bff',
      backgroundColor: '#ffffff',
    };
  }

  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );

  return {
    fontFamily: editorState.styles.font,
    color: bgColorObject.textCss.color,
    accentColor: editorState.styles.accentColor,
    backgroundColor: bgColorObject.bgCss.backgroundColor,
  };
}
