import { OffthreadVideo, Sequence } from "remotion";
import { getAssetPath } from "../../../remotionHelpers/assets";

export const Confetti: React.FC<{ displayAnimations: boolean, currentRemotionFrame: number }> = ({ displayAnimations, currentRemotionFrame }) => {
  const confettiVideoPath = getAssetPath("confettiVideo");
  const showCreative = () => {
    if (!displayAnimations) return null;

    return (
        <OffthreadVideo
          src={confettiVideoPath}
          transparent={true}

          muted
        />
    );
  };

  return showCreative();
};