export function calculateFontSize(
  textLength: number,
  referenceLengths: number[],
  fontSizes: number[]
): number {
  const clampedLength = Math.min(
    Math.max(textLength, referenceLengths[0]),
    referenceLengths[referenceLengths.length - 1]
  );

  let fontSize = fontSizes[0]; // Start with the maximum font size

  for (let i = 0; i < referenceLengths.length - 1; i++) {
    const startLength = referenceLengths[i];
    const endLength = referenceLengths[i + 1];
    const startSize = fontSizes[i];
    const endSize = fontSizes[i + 1];

    if (clampedLength >= startLength && clampedLength <= endLength) {
      const ratio = (clampedLength - startLength) / (endLength - startLength);
      fontSize = startSize + (endSize - startSize) * Math.pow(ratio, 1.5);
      break;
    }
  }

  return Math.max(fontSizes[fontSizes.length - 1], Math.min(fontSizes[0], fontSize));
}