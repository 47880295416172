import React, { useEffect, useState } from "react";
import { AbsoluteFill, Img, interpolate } from "remotion";
import { easeInOutExpo } from "../../../remotionConstant";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";

interface IntroProjectLogoProps {
  currentRemotionFrame: number;
  durationInFrames: number;
  content: any;
  isEditable: boolean;
  displayAnimations: boolean;
}

export const IntroProjectLogo: React.FC<IntroProjectLogoProps> = ({
  currentRemotionFrame,
  durationInFrames,
  content,
  displayAnimations,
  isEditable,
}) => {
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);
  let logoPath = content.image1.value;
  useEffect(() => {
    const img = new Image();
    img.src = logoPath;
    img.onload = () => {
      setOriginalWidth(img.width);
      setOriginalHeight(img.height);
    };
  }, [content]);

  const animationStart = 60;
  // const logoHeight = 200;

  const initialEvolve = interpolate(currentRemotionFrame - 5, [0, 30], [0, 1], {
    extrapolateRight: "clamp",
    easing: easeInOutExpo,
  });

  const flyingEvolve = interpolate(
    currentRemotionFrame - animationStart,
    [0, 30],
    [0, 1],
    {
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );

  const scaleFactor = interpolate(
    currentRemotionFrame < animationStart ? initialEvolve : 1,
    [0, 1],
    [2, 1],
    { extrapolateRight: "clamp" }
  );

  const opacity = interpolate(
    currentRemotionFrame,
    [durationInFrames - 10, durationInFrames],
    [1, 0],
    { extrapolateRight: "clamp" }
  );

  const logoStyle: React.CSSProperties = {
    // position: "absolute",
    transform: displayAnimations
      ? `scale(${scaleFactor}) translateY(${interpolate(
          currentRemotionFrame < animationStart ? initialEvolve : 1,
          [0, 1],
          [100, 0],
          { extrapolateRight: "clamp" }
        )}px)`
      : `scale(1) translateY(0px)`,
    // height: `${logoHeight}px`,
    opacity: displayAnimations ? opacity : 1,
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={logoStyle}>
        {isEditable ? <Editable content={content.image1} isEditable={isEditable} hint="Your logo. Square works the best.">
          <Img className={""} src={logoPath} style={{ height: "10em" }} />
        </Editable> : <Img className={""} src={logoPath} style={{ height: "10em" }} />}

        {/* <h1>{toString(isEditable)}</h1> */}
        
        {/* <Editable content={content.image1}> */}
          {/* <Img className={""} src={logoPath} style={{ height: "10em" }} /> */}
        {/* </Editable> */}
      </div>
    </div>
  );
};
