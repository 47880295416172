// SideFeatures.tsx
import React, { CSSProperties, useMemo } from "react";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import Feature from "./Feature";

export const SideFeatures = ({
  content,
  currentRemotionFrame,
  durationInFrames,
  displayAnimations,
  isEditable,
  commonStyles,
  bgColorObject,
  editorState,
}) => {
  const featureDelay = 5;

  const containerStyle: CSSProperties = useMemo(
    () => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      height: "100%",
      padding: "2.625em",
    }),
    []
  );

  {/*  display a default feature if user delete all feature and needs something to click on to add new features*/}
  const showSideFeatures = () => (
    <>{content.list1.value.length === 0 && (
      <Feature
        text={"Default feature text"}
        delay={featureDelay}
        commonStyles={commonStyles}
        currentRemotionFrame={currentRemotionFrame}
        displayAnimations={displayAnimations}
        bgColorObject={bgColorObject}
        editorState={editorState}
      />
    )}
    {content.list1?.value.map((item, index) => (
      <Feature
        key={index}
        text={item.text}
        delay={index * featureDelay}
        commonStyles={commonStyles}
        currentRemotionFrame={currentRemotionFrame}
        displayAnimations={displayAnimations}
        bgColorObject={bgColorObject}
        editorState={editorState}
      />
    ))}</>
  );
  return (
    <div style={ containerStyle }>
      {isEditable ?
        <Editable
          isEditable={isEditable}
          content={content.list1}
          hint="Add 4-6 features of your project."
        >
          
          {showSideFeatures()}
        </Editable>
        : 
        showSideFeatures()
      }
    </div>
  );
};

export default SideFeatures;
