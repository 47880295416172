// app/components/EditorSyncContext.tsx

import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { useFetcher } from "@remix-run/react";
import pkg from "lodash";
import { EditorState, useEditorState } from "./EditorStateContext";
import { getRoute } from "../../../routes";
import { notifications } from "@mantine/notifications";

const { debounce, isEqual } = pkg;

type EditorSyncContextType = {
  isSaving: boolean;
  hasPendingChanges: boolean;
  setIsSaving: (isSaving: boolean) => void;
  lastError: string | null;
};

const EditorSyncContext = createContext<EditorSyncContextType | undefined>(
  undefined
);

const initialEditorState: EditorState = {
  styles: {
    accentColor: "",
    font: "",
    backgroundColorID: "white",
  },
  aspectRatio: "horizontal",
  content: {},
  videoId: null,
};

export function EditorSyncProvider({ children }: { children: ReactNode }) {
  const [isSaving, setIsSaving] = useState(false);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [lastError, setLastError] = useState<string | null>(null);
  const updateFetcher = useFetcher();
  const { editorState } = useEditorState();

  const previousStateRef = useRef<EditorState>(initialEditorState);
  const hasInitialStateBeenSet = useRef(false);

  const adaptStateForDatabase = (state: EditorState) => {
    return {
      // when you're using FormData to send complex JavaScript objects, they're automatically converted to strings, resulting in '[object Object]'.
      // So we use JSON.stringify here, and then JSON.parse here routes/videos.update.$id.tsx
      content: JSON.stringify(state.content),
      style: JSON.stringify(state.styles),
      videoAspectRatio: state.aspectRatio,
    };
  };

  const syncToDatabase = useCallback(
    debounce((state: EditorState) => {
      if (isEqual(state, initialEditorState)) {
        return;
      }

      if (!hasInitialStateBeenSet.current) {
        hasInitialStateBeenSet.current = true;
        previousStateRef.current = state;
        setHasPendingChanges(false);
        return;
      }

      if (isEqual(state, previousStateRef.current)) {
        setHasPendingChanges(false);
        return;
      }

      setIsSaving(true);
      setHasPendingChanges(false);
      const adaptedState = adaptStateForDatabase(state);

      updateFetcher.submit(adaptedState, {
        method: "post",
        action: getRoute("updateVideo", { video_id: state.videoId }),
      });

      previousStateRef.current = state;
    }, 1000),
    [updateFetcher]
  );

  useEffect(() => {
    if (updateFetcher.state === "idle") {
      if (isSaving) {
        setIsSaving(false);
        if (updateFetcher.data?.error) {
          setLastError(updateFetcher.data.error);
          notifications.show({
            title: "Error",
            message: updateFetcher.data.error,
            color: "red",
          });
        } else {
          setLastError(null);
          // notifications.show({
          //   title: "Success",
          //   message: "Changes saved successfully",
          //   color: "green",
          // });
        }
      }
    }
  }, [updateFetcher.state, updateFetcher.data, isSaving]);

  useEffect(() => {
    if (!isEqual(editorState, previousStateRef.current)) {
      setHasPendingChanges(true);
    }
    syncToDatabase(editorState);
  }, [editorState, syncToDatabase]);

  const value: EditorSyncContextType = {
    isSaving,
    hasPendingChanges,
    setIsSaving,
    lastError,
  };

  return (
    <EditorSyncContext.Provider value={value}>
      {children}
    </EditorSyncContext.Provider>
  );
}

export function useEditorSync() {
  const context = useContext(EditorSyncContext);
  if (context === undefined) {
    throw new Error("useEditorSync must be used within an EditorSyncProvider");
  }
  return context;
}
