import React, { useMemo } from "react";
import { AbsoluteFill, Img, interpolate, Sequence } from "remotion";
import { easeInOutExpo } from "../../../remotionConstant";
import {
  EditableFrameWrapper,
} from "../../../FrameContentEditors/EditableFrameWrapper";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import { Confetti } from "./Confetti";
import { FinalCta } from "./FinalCta";

export function Frame3Index({
  frameData,
  displayAnimations = false,
  isEditable = false, // it is passed as false to the Root, but we set also false here for protection (just in case)
  editorState,
  currentRemotionFrame = 0,
}: {
  frameData: any;
  displayAnimations?: boolean;
  isEditable?: boolean;
  editorState: any;
  currentRemotionFrame?: number;
}) {
  // const { editorState } = useEditorState();
  const content = frameData.content || {};

  // const { durationInFrames } = frameData;
  const commonStyles = getCommonStyles(editorState);
  const containerStyle = useMemo(
    () => ({
      fontFamily: commonStyles.fontFamily,
      color: commonStyles.color,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    [commonStyles]
  );
  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );

  const confettiStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: 50000,
    bottom: 260,
    width: 1200,
    // left: "0",
    // left: "50%",
    left: "300px",
    // right: 0,
    // margin: "auto",
    // transform: "translateX(-50%)",
  };
  return (
    <EditableFrameWrapper frameData={frameData} isEditable={isEditable}>
      <AbsoluteFill
        style={{
          ...containerStyle,
          backgroundColor: bgColorObject["backgroundColor"],
        }}
      >
         <AbsoluteFill style={{ zIndex: 1500, height: "100%" }}>
          <FinalCta 
            currentRemotionFrame={currentRemotionFrame}
            content={content}
            displayAnimations={displayAnimations}
            isEditable={isEditable}
            commonStyles={commonStyles}
            bgColorObject={bgColorObject}
            editorState={editorState}
          ></FinalCta>
        </AbsoluteFill>
        
        {/* we do not need to show confetti in the timeline (preview) */}
        {/* we also do not need the conffetti when isEditable=true because it will overlap all the editable content and user won't be able to click on it */}
        {displayAnimations && !isEditable && <Sequence from={50} durationInFrames={120}>
          <div style={{ ...confettiStyle }}>
            <Confetti displayAnimations={displayAnimations} currentRemotionFrame={currentRemotionFrame}></Confetti>
          </div>
        </Sequence>}
      </AbsoluteFill>
    </EditableFrameWrapper>
  );
}
