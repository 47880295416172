import {
  interpolate,
  Img,
  OffthreadVideo,
} from "remotion";

import {
  getLaptopPositionX,
  getLaptopPositionY,
  getLaptopPositionZ,
  getLaptopScaleX,
  getLaptopScaleY,
  getLaptopScaleZ,
  getLaptopRotationX,
  getLaptopRotationY,
  getLaptopRotationZ,
  getOpacity,
} from "./browserMockupAnimations";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";

export const BrowserWindowWithVideo = ({
  content,
  currentRemotionFrame,
  durationInFrames,
  displayAnimations,
  isEditable,
}) => {
  const frame = currentRemotionFrame; // Using currentRemotionFrame as the frame number

  const positionX = getLaptopPositionX(frame);
  const positionY = getLaptopPositionY(frame);
  const positionZ = getLaptopPositionZ(frame);
  const scaleX = getLaptopScaleX(frame);
  const scaleY = getLaptopScaleY(frame);
  const scaleZ = getLaptopScaleZ(frame);
  const rotationX = getLaptopRotationX(frame);
  const rotationY = getLaptopRotationY(frame);
  const rotationZ = getLaptopRotationZ(frame);
  const opacity = getOpacity(frame);

  let uspCreative = content.video1;
  const isVideo =
    uspCreative.value.toLowerCase().endsWith(".mp4") ||
    uspCreative.value.toLowerCase().endsWith(".mov") ||
    uspCreative.value.toLowerCase().endsWith(".webm");

  const scrollProgress = interpolate(
    currentRemotionFrame,
    [0, durationInFrames],
    [0, 1],
    {
      extrapolateRight: "clamp",
    }
  );

  const showCreative = () => (
    isVideo ? (
      displayAnimations ? (
        <OffthreadVideo
          src={uspCreative.value}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        //   for the preview display the regular video tag
        //   because OffthreadVideo uses getCurrentFrame from Remotion which will throw an error
        //   because we display it outside of the Remotion context (its player)
        <video
          src={uspCreative.value + "#t=1"}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      )
    ) : (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Img
          src={uspCreative.value}
          style={{
            width: "100%",
            transform: displayAnimations
              ? `translateY(${interpolate(
                  scrollProgress,
                  [0, 1],
                  [0, -100]
                )}%)`
              : "translateY(0)",
          }}
        />
      </div>
    )
  );

  const showDomain = () => (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: "white",
        borderRadius: "0.3125em",
        padding: "0.3125em 0.625em",
        fontSize: "0.875em",
        color: "#333",
      }}
    >
      {content.projectDomainName?.value || "paracast.io"}
    </div>  
  );

  return (
    <div
      style={{
        width: "75em",
        height: "42.2em",
        backgroundColor: "white",
        borderRadius: "0.625em",
        overflow: "hidden",
        boxShadow: "0 0.625em 1.875em rgba(0, 0, 0, 0.2)",
        transform: displayAnimations
          ? `
            perspective(1000px)
            translate3d(${positionX * 100}%, ${positionY * 100}%, ${
              positionZ * 100
            }px)
            scale3d(${scaleX}, ${scaleY}, ${scaleZ})
            rotateX(${rotationX}rad)
            rotateY(${rotationY}rad)
            rotateZ(${rotationZ}rad)
          `
          : "none",
        opacity: displayAnimations ? opacity : 1,
      }}
    >
      <div
        style={{
          background: "linear-gradient(to bottom, #e8e8e8, #d1d1d1)",
          padding: "0.625em",
          display: "flex",
          alignItems: "center",
          borderBottom: "0.0625em solid #b1b1b1",
        }}
      >
        <div
          style={{ display: "flex", gap: "0.375em", marginRight: "0.9375em" }}
        >
          {["#ff5f57", "#febc2e", "#28c840"].map((color, index) => (
            <div
              key={index}
              style={{
                width: "0.75em",
                height: "0.75em",
                borderRadius: "50%",
                backgroundColor: color,
              }}
            />
          ))}
        </div>
        {isEditable ? <Editable isEditable={isEditable} content={content.projectDomainName} style={{ width: "100%" }} hint="Domain name of your project, e.g. website.com.">
          {showDomain()}
        </Editable> : showDomain()}
      </div>
      <div
        style={{
          height: "calc(100% - 2.5em)",
          overflow: "hidden",
        }}
      >
        {isEditable ? <Editable
        isEditable={isEditable}
          content={uspCreative}
          hint={"Your project demo. Make a basic 15 sec video record of your screen while working with your app. 1920x1080"}
        >
          {showCreative()}
        </Editable> : showCreative()}
      </div>
    </div>
  );
};
