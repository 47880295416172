import React from "react";
import { Img, interpolate } from "remotion";
import { easeInOutExpo } from "../../../remotionConstant";
import { isColorDark } from "../../../remotionHelpers/isColorDark";
import { getAssetPath } from "../../../remotionHelpers/assets";
import { calculateFontSize } from "../../../remotionHelpers/calculateFontSize";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";

export const FinalCta = ({
  currentRemotionFrame,
  content,
  displayAnimations,
  isEditable,
  bgColorObject,
  editorState,
  commonStyles
}) => {
  const frame = currentRemotionFrame;
  let textColorCss = {
    ...bgColorObject["textCss"],
  };

  const delay = 0;
  const buttonPressDelay = - 20; // make it happen a little earlier. if changed also change conffetti
  
  // for preview in the Timeline we want to display the final value
  const getFinalState = (initialValue, finalValue) => {
    return displayAnimations ? interpolate(frame, [delay, delay + 10], [initialValue, finalValue], {
      extrapolateRight: "clamp",
    }) : finalValue;
  };

  const logoOpacity = getFinalState(0, 1);
  const logoTop = getFinalState(50, 0);

  const logoTranslateY = displayAnimations
    ? interpolate(frame, [100, 110], [0, 14], {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      })
    : 0;

  const projectNameOpacity = displayAnimations
    ? interpolate(frame, [delay + 5, delay + 15, buttonPressDelay + 120, buttonPressDelay + 130], [0, 1, 1, 0], {
        extrapolateRight: "clamp",
      })
    : 1;
  const projectNameTop = interpolate(frame, [delay + 5, delay + 15], [3.1, 0], {
    extrapolateLeft: "clamp",
    extrapolateRight: "clamp",
    easing: easeInOutExpo,
  });
  const projectNameScale = displayAnimations
    ? interpolate(frame, [buttonPressDelay + 120, buttonPressDelay + 130], [1, 0.8], {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      })
    : 0.8;

  const ctaButtonOpacity = displayAnimations
    ? interpolate(frame, [delay + 10, delay + 20, buttonPressDelay + 120, buttonPressDelay + 130], [0, 1, 1, 0], {
        extrapolateRight: "clamp",
      })
    : 1;
  const ctaButtonTop = interpolate(frame, [delay + 10, delay + 20], [3.1, 0], {
    extrapolateLeft: "clamp",
    extrapolateRight: "clamp",
    easing: easeInOutExpo,
  });
  const ctaButtonScale = displayAnimations
    ? interpolate(frame, [buttonPressDelay + 120, buttonPressDelay + 130], [1, 0.8], {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      })
    : 0.8;

  const pointerOpacity = interpolate(frame, [buttonPressDelay + 40, buttonPressDelay + 50], [0, 1], {
    extrapolateRight: "clamp",
  });
  const buttonTranslateX = displayAnimations 
    ? interpolate(frame, [buttonPressDelay + 70, buttonPressDelay + 75, buttonPressDelay + 80], [0, -0.4, 0], {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }) 
    : 0;
  const buttonTranslateY = displayAnimations 
    ? interpolate(frame, [buttonPressDelay + 70, buttonPressDelay + 75, buttonPressDelay + 80], [0, 0.4, 0], {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }) 
    : 0;
  const buttonShadow = displayAnimations 
    ? interpolate(frame, [buttonPressDelay + 70, buttonPressDelay + 75, buttonPressDelay + 80], [0.27, 0.4, 0.27], {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      }) 
    : 0.27;

  const pointerTranslateX = interpolate(
    frame,
    [buttonPressDelay + 50, buttonPressDelay + 70],
    [200, 0],
    {
      extrapolateLeft: "clamp",
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );
  const pointerTranslateY = interpolate(
    frame,
    [buttonPressDelay + 50, buttonPressDelay + 70],
    [150, 0],
    {
      extrapolateLeft: "clamp",
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );

  const accentColor = editorState.styles.accentColor;

  const titleFontSize = calculateFontSize(
    content.projectTitle?.value.length || 0,
    [12, 22, 35, 60, 75], // referenceLengths
    [6, 4.5, 3.5, 3, 2.5] // fontSizes (max to min)
  );

  const showImage = () => (
    <Img src={content.image1.value} style={{ height: "18em" }} />
  );

  const showTitle = () => (
    <div
      style={{
        ...commonStyles,
        fontSize: `${titleFontSize}em`,
        lineHeight: 1.2,
        fontWeight: 600,
        ...textColorCss,
      }}
    >
      {content.projectTitle.value || "Paracast.io"}
    </div>
  );
  const showButton = () => (
    <div
    style={{
      padding: "0.7em 2em",
      position: "relative",
      fontSize: "4em",
      borderRadius: "0.3em",
      backgroundColor: accentColor,
      border: "0.1em solid black",
      color: isColorDark(accentColor) ? "white" : "black",
      textAlign: "center",
      display: "inline-block",
      fontWeight: 600,
      boxShadow: bgColorObject["isDark"]
        ? `-${buttonShadow}em ${buttonShadow}em 0 white`
        : `-${buttonShadow}em ${buttonShadow}em 0 black`,
    }}
  >
    {content.text1.value  || "Try our tool now!"}
  </div>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          opacity: logoOpacity,
          position: "relative",
          top: logoTop,
          transform: `translateY(${logoTranslateY}em)`,
        }}
      >
        {isEditable ? <Editable isEditable={isEditable} content={content.image1} hint={"Your logo. Square .svg works the best 🤌"}>
        {showImage()}
      </Editable> : showImage()}
      </div>
      <div
        style={{
          opacity: projectNameOpacity,
          position: "relative",
          top: `${projectNameTop}em`,
          letterSpacing: "0.02em",
          margin: "2.6em 0 2.6em 0", // 40px
          transform: `scale(${projectNameScale})`,
        }}
      >
        {isEditable ? <Editable isEditable={isEditable} content={content.projectTitle} hint={"Your project's name."}>
          {showTitle()}
          </Editable> : showTitle()
        }
      </div>
      <div
        style={{
          opacity: ctaButtonOpacity,
          position: "relative",
          top: `${ctaButtonTop}em`,
          transform: `translate(${buttonTranslateX}em, ${buttonTranslateY}em) scale(${ctaButtonScale})`,
        }}
      >
        {isEditable ? <Editable isEditable={isEditable} content={content.text1} hint={"Call to action, e.g. 'Try our tool now!'."}>
        {showButton()}
      </Editable> : showButton()}
        
        {displayAnimations && <div
          style={{
            opacity: pointerOpacity,
            position: "absolute",
            width: "100%",
            top: "50%",
            left: "0",
            right: 0,
            margin: "auto",
            transform: `translate(${pointerTranslateX}px, ${pointerTranslateY}px)`,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Img
              style={{ height: "6.5em" }}
              src={getAssetPath("pointerIcon")}
            />
          </div>
        </div>}
      </div>
    </div>
  );
};