import React from "react";
import { interpolate } from "remotion";
import { easeInOutExpo, FPS } from "../../../remotionConstant";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";


export const GrowingCircle = ({
    currentRemotionFrame,
    content,
    displayAnimations,
    isEditable,
    commonStyles,
    bgColorObject,
    editorState,
}) => {
    const frame = currentRemotionFrame;
    const delaySecondCircleInFrames = 16;
    const accentColor = editorState.styles.accentColor;
    
  const durationInFrames = 30;
  const delayStartInFrames = 220;

  const circleSize1 = interpolate(
    frame - delayStartInFrames,
    [0, durationInFrames],
    [0, Math.max(1920, 1080) * 2],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeInOutExpo,
    }
  );
  
  const circleSize2 = interpolate(
    frame - delayStartInFrames - delaySecondCircleInFrames,
    [0, durationInFrames - delaySecondCircleInFrames],
    [0, Math.max(1920, 1080) * 2],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeInOutExpo,
    }
  );
  

  if (!displayAnimations) {
    return null; // Don't render anything in static preview
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: `${562/16}em`,
          left: `${328/16}em`,
          width: `${circleSize1/16}em`,
          height: `${circleSize1/16}em`,
          borderRadius: "50%",
          backgroundColor: accentColor,
          transform: "translate(-50%, -50%)",
          zIndex: 1000,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: `${562/16}em`,
          left: `${328/16}em`,
          width: `${circleSize2/16}em`,
          height: `${circleSize2/16}em`,
          borderRadius: "50%",
          ...bgColorObject["bgCss"],
          transform: "translate(-50%, -50%)",
          zIndex: 1010,
        }}
      />
    </>
  );
};