import React, { useMemo } from "react";
import { AbsoluteFill, Img, interpolate, interpolateColors } from "remotion";
import { easeInOutExpo } from "../../../remotionConstant";
import { EditableFrameWrapper } from "../../../FrameContentEditors/EditableFrameWrapper";
import { springTiming, TransitionSeries } from "@remotion/transitions";
import { wipe } from "@remotion/transitions/wipe";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import { Audiences } from "./Audiences";
import { SingleTestimonial } from "./SingleTestimonial";
import { allFrames } from "../../allFrames";
import { BackgroundImage } from "@mantine/core";
import { GrowingCircle } from "./GrowingCircle";

interface Frame4IndexProps {
  frameData: any;
  displayAnimations?: boolean;
  isEditable?: boolean;
  editorState: any;
  currentRemotionFrame?: number;
}

export function Frame4Index({
  frameData,
  displayAnimations = false,
  isEditable = false,
  editorState,
  currentRemotionFrame = 0,
}: Frame4IndexProps) {
  const content = frameData.content || {};
  const { durationInFrames } = frameData;
  const commonStyles = getCommonStyles(editorState);
  
  const containerStyle = useMemo(
    () => ({
      fontFamily: commonStyles.fontFamily,
      color: commonStyles.color,
      display: "flex",
    }),
    [commonStyles]
  );

  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );


  // Simulated wipe transition
  const wipeProgress = interpolate(
    currentRemotionFrame,
    [0, 10], // Adjust this range to control the duration of the wipe
    [100, 0],
    {
      extrapolateRight: 'clamp',
      extrapolateLeft: 'clamp'
    }
  );

  const wipeStyle = displayAnimations
    ? {
        clipPath: `inset(0 ${wipeProgress}% 0 0)`,
      }
  : {};
  
  return (
    <EditableFrameWrapper frameData={frameData} isEditable={isEditable}>
          <AbsoluteFill
            style={{
              ...wipeStyle,
              ...containerStyle,
              backgroundColor: displayAnimations
                ? interpolateColors(
                    currentRemotionFrame,
                    [0, 15],
                    [editorState.styles.accentColor, bgColorObject["backgroundColor"]]
                  )
                : bgColorObject["backgroundColor"],
            }}
          >
            <AbsoluteFill >
              <Audiences 
                currentRemotionFrame={currentRemotionFrame}
                content={content}
                displayAnimations={displayAnimations}
                isEditable={isEditable}
                commonStyles={commonStyles}
                bgColorObject={bgColorObject}
                editorState={editorState}
              />
              <SingleTestimonial
                currentRemotionFrame={currentRemotionFrame}
                content={content}
                displayAnimations={displayAnimations}
                isEditable={isEditable}
                commonStyles={commonStyles}
                bgColorObject={bgColorObject}
                editorState={editorState}
              />
              <GrowingCircle currentRemotionFrame={currentRemotionFrame}
                content={content}
                displayAnimations={displayAnimations}
                isEditable={isEditable}
                commonStyles={commonStyles}
                bgColorObject={bgColorObject}
                editorState={editorState}/>
            </AbsoluteFill>
          </AbsoluteFill>
    </EditableFrameWrapper>
  );
}