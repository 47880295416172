const BG_COLOR_OPTIONS = [
  {
    title: "black bg",
    id: "black",
    isDark: true,
    statsNumberOfUses: 0,
    backgroundColor: "black",
    bgCss: {
      backgroundColor: "black",
    },
    textCss: {
      color: "white",
    },
  },
  {
    title: "white bg",
    id: "white",
    isDark: false,
    statsNumberOfUses: 0,
    backgroundColor: "white",
    bgCss: {
      backgroundColor: "white",
    },
    textCss: {
      color: "black",
    },
  },
];
export function findBackgroundColorObjectByID(
  id: string
): (typeof BG_COLOR_OPTIONS)[number] {
  // console.log(`Searching for background object with ID: ${id}`);
  // console.log('BG_COLOR_OPTIONS inside function:', BG_COLOR_OPTIONS);

  let foundObject: (typeof BG_COLOR_OPTIONS)[number] | undefined;
  for (const obj of BG_COLOR_OPTIONS) {
    // console.log(`Comparing ID: ${obj.id} with target ID: ${id}`);
    if (String(obj.id) === String(id)) {
      foundObject = obj;
      break;
    }
  }

  if (foundObject) {
    // console.log(`Found background object:`, foundObject);
  } else {
    console.error(
      `No background object found with ID: ${id}. Returning first from the array.`
    );
    foundObject = BG_COLOR_OPTIONS[0];
  }

  return foundObject;
}
