// app/components/NotFound.tsx

import {
  Button,
  Container,
  Group,
  Text,
  Title
} from "@mantine/core";
import { Link } from "@remix-run/react";
import { IconHeartBroken, IconHome } from "@tabler/icons-react";
import { HelpButton } from "~/components/AppShell/HelpButton";
import { getRoute } from "~/routes";

export function NotFound() {
  return (
    <Container size={420} my={40}>
      <Text ta={"center"}>
        {<IconHeartBroken size={"30px"} color={"red"}></IconHeartBroken>}
      </Text>
      <Title ta={"center"}>404 </Title>

      <Text c="dimmed" ta={"center"} size="lg" mt="xl">
        Oops! The page you're looking doesn't exist.
      </Text>
      <Group justify="center" mt="xl">
        <Button
          component={Link}
          to={getRoute("home")}
          size="md"
          rightSection={<IconHome size={"1rem"} />}
        >
          Take me back to home page
        </Button>

        <HelpButton></HelpButton>
      </Group>
    </Container>
  );
}
