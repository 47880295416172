import { boolean } from "drizzle-orm/pg-core";

export function triggerAnalyticsEvent(
  eventTitle: AnalyticsEventTypes,
  data?: object,
  type?: string
) {
  // logbeat logic
}
// import axios, { AxiosResponse } from "axios";
// import { urls } from "../data/urls";
//
// interface DiscordEmbed {
//   title: string;
//   description: string;
//   color: number;
// }
//
// interface Data {
//   embeds: DiscordEmbed[];
// }
//
// export const sendToDiscordAsync = (
//   data: Data,
// ): Promise<AxiosResponse<any>> => {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(urls.discordWebhooks.leads, data)
//       .then((response) => resolve(response))
//       .catch((error) => {
//         console.error(error);
//         reject(error);
//       });
//   });
// };
export enum AnalyticsEventTypes {
  LOGIN_WITH_EMAIL = "login_with_email",
  LOGIN_WITH_EMAIL_ERROR = "login_with_email_error",
  AUTHORIZE_WITH_OAUTH = "authorize_with_oauth",
  AUTHORIZE_WITH_OAUTH_ERROR = "authorize_with_oauth_error",
  LOGOUT = "logout",
  SIGNUP = "signup",
  SIGNUP_ERROR = "signup_error",
  CREATE_NEW_VIDEO = "create_new_video",
  CREATE_NEW_VIDEO_ERROR = "create_new_video_error",
  DELETE_VIDEO = "delete_video",
  DELETE_VIDEO_ERROR = "delete_video_error",
  CLONE_VIDEO = "clone_video",
  CLICK_PREVIEW_TEMPLATE = "click_preview_template",
  CLICK_CREATE_FROM_THIS_TEMPLATE = "click_create_from_this_template",
  UPGRADE_FROM_FREE = "upgrade_from_free",
  UPGRADE_FROM_FREE_ERROR = "upgrade_free_error",
  CLICK_RENDER_VIDEO = "click_render_video",
  RENDER_VIDEO_DONE = "render_video_done",
  RENDER_VIDEO_ERROR = "render_video_error",
  CHANGE_ASPECT_RATIO = "change_aspect_ratio",
  CHANGE_ACCENT_COLOR = "change_accent_color",
  CHANGE_BACKGROUND_COLOR = "change_accent_color",
  CHANGE_FONT = "change_font",
  CLICK_DARK_MODE = "click_dark_mode",
  CLICK_HOME_SCREEN = "click_home_screen",
  CLICK_SEE_ALL_VIDEOS = "click_see_all_videos",
  SWITCH_PLAN = "switch_plan",
  CANCEL_PLAN = "cancel_plan",
  SWITCH_PLAN_ERROR = "switch_plan_error",
  CANCEL_PLAN_ERROR = "cancel_plan_error",
  UPGRADE_PLAN = "upgrade_plan",
  DOWNGRADE_PLAN = "downgrade_plan",
  CHANGE_EMAIL = "change_email",
  ADD_FRAME = "add_frame",
  CLICK_INSERT_FRAME = "click_insert_frame",
  ADD_NEW_FRAME_TO_VIDEO = "add_new_frame_to_video",
  UPDATE_VIDEO = "update_video",
  UPDATE_VIDEO_ERROR = "update_video_error",
  CLICK_EDIT_VIDEO = "click_edit_video",
  CLICK_DELETE_FRAME = "click_delete_frame",
  CLICK_USER_SETTINGS_TAB = "click_user_settings_tab",
  CLICK_ACCOUNT_DROPDOWN = "click_account_dropdown",
  VISIT_PLANS_PAGE = "visit_plans_page",
  SET_VIDEO_TITLE = "set_video_title",
}
