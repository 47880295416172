import React from "react";
import { interpolate, random, spring } from "remotion";

import { FEATURES_IN_TRANSITION_FRAME } from "../Frame1/Frame1Index";
import { useEditorState } from "../../../../AppContent/state/EditorContext";
import { FPS } from "../../../remotionConstant";

const Feature = ({
  text,
  delay,
  commonStyles,
  currentRemotionFrame,
  bgColorObject,
  displayAnimations,
  editorState,
}) => {
  let sideFeaturesStart = FEATURES_IN_TRANSITION_FRAME;
  const accentColor = editorState.styles.accentColor;

  const enterAnimation = spring({
    fps: FPS,
    frame: currentRemotionFrame - (sideFeaturesStart + delay),

    config: { damping: 100, stiffness: 200, mass: 0.5 },
  });
  const moveAnimation = spring({
    fps: FPS,
    frame: currentRemotionFrame - (sideFeaturesStart + 155),
    config: { damping: 100, stiffness: 200, mass: 0.5 },
    durationInFrames: 40,
  });

  const opacity = interpolate(enterAnimation, [0, 1], [0, 1]);
  const translateY = interpolate(enterAnimation, [0, 1], [50, 0]);
  const scale = interpolate(enterAnimation, [0, 1], [0.95, 1]);
  const shadowOpacity = interpolate(enterAnimation, [0, 1], [0, 1]);
  const shadowSize = interpolate(enterAnimation, [0, 1], [0, 14]);

  const randomDistance = Math.floor(random(`feature-${text}`) * 801) + 1050;
  const translateX = interpolate(moveAnimation, [0, 1], [0, -randomDistance]);
  const featureStyle = {
    opacity: displayAnimations ? opacity : 1,
    // transform: displayAnimations
    //   ? `translateY(${translateY}px) scale(${scale})`
    //   : "none",
    transform: displayAnimations
      ? `translateX(${translateX}px) translateY(${translateY}px) scale(${scale})`
      : "none",
    display: "flex",
    alignItems: "center",
    borderRadius: "0.75em",
    padding: "1.25em 2.125em 1.25em 1.375em",
    marginBottom: "2.5em",
    border: "0.25em solid",
    ...bgColorObject["bgCss"],
    borderColor: commonStyles.color,
    boxShadow: `${-shadowSize}px ${shadowSize}px 0 rgba(${
      commonStyles.color === "white" ? "255, 255, 255" : "0, 0, 0"
    }, ${shadowOpacity})`,
    width: "max-content",
  };

  return (
    <div style={featureStyle}>
      <svg
        width="3.125em"
        height="3.125em"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          marginRight: "1.5em",
          marginTop: "0.75em",
          marginLeft: "0.625em",
        }}
      >
        <path
          d="M43.211 0L17.7982 25.4189L6.78896 14.4071L0 21.1976L17.7982 39L50 6.79055L43.211 0Z"
          fill={accentColor}
        />
      </svg>
      <div style={{ fontSize: "2.75em" }}>{text}</div>
    </div>
  );
};

export default Feature;
