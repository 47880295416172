import React, { useState, useCallback } from "react";
import { Popover, Textarea, Box, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "@mantine/hooks";
import pkg from "lodash";
const { debounce, isEqual } = pkg;

interface FrameTextEditorProps {
  content: any;
  isEditable: boolean;
  handleContentChange: (newValue: string) => void;
  children: React.ReactNode;
  style?: any;
  hint?: string | undefined;
}

export function FrameTextEditor({
  content,
  isEditable,
  handleContentChange,
  children,
  style,
  hint,
}: FrameTextEditorProps) {
  const [isOpen, setIsOpen] = useState(false);
  let { t } = useTranslation();
  const ref = useClickOutside(() => setIsOpen(false));

  const debouncedHandleContentChange = useCallback(
    debounce((value: string) => {
      handleContentChange(value);
    }, 300),
    [handleContentChange]
  );

  return (
    <Popover
      opened={!isEditable ? false : isOpen}
      width={content?.value?.length < 100 ? 300 : 500}
      position="left"
      offset={20}
      withArrow
      trapFocus
      transitionProps={{ duration: 0 }}
    >
      <Popover.Target>
        <Box
          title={isEditable ? "Edit text" : ""}
          className={"frame__editable_content"}
          onClick={() => setIsOpen((o) => !o)}
          style={{ ...style }}
        >
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown 
        style={{maxWidth: "30%"}} //because on small screens big text areas sometimes go beyond the screen (e.g. SyntaxHighlightedCode on 14")
        ref={ref}>
        {hint && (
          <Text size={"sm"} c="dimmed" mb={"xs"}>
            {hint}
          </Text>
        )}
        <Textarea
          size={"lg"}
          defaultValue={content.value}
          onChange={(e) => debouncedHandleContentChange(e.target.value)}
          autosize
          minRows={1}
        
        />
      </Popover.Dropdown>
    </Popover>
  );
}
