// app/components/AppContent/Editor/FrameContentEditors/FrameListEditor.tsx

import React, { useState, useRef, useEffect } from "react";
import {
  Popover,
  Box,
  Button,
  TextInput,
  Text,
  Group,
  ActionIcon,
  Stack,
  Progress,
  Paper,
  ScrollArea,
} from "@mantine/core";
import {
  IconPlus,
  IconTrash,
  IconUpload,
  IconArrowUp,
  IconArrowDown,
  IconX,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useFetcher } from "@remix-run/react";
import { useEditorState } from "~/components/AppContent/state/EditorStateContext";
import pkg from "lodash";
const { debounce, isEqual } = pkg;

interface ListItem {
  text: string;
  secondaryText?: string;
  image?: string;
}

interface FrameListEditorProps {
  content: {
    type: "list";
    value: ListItem[];
  };
  isEditable: boolean;
  handleContentChange: (newValue: ListItem[]) => void;
  children: React.ReactNode;
  style?: any;
  hint?: string;
}

export function FrameListEditor({
  content,
  isEditable,
  handleContentChange,
  children,
  style,
  hint,
}: FrameListEditorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState<ListItem[]>(content.value);
  const [uploadingIndex, setUploadingIndex] = useState<number | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [hoveredImageIndex, setHoveredImageIndex] = useState<number | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const { editorState } = useEditorState();

  useEffect(() => {
    if (fetcher.data && fetcher.state === "idle" && uploadingIndex !== null) {
      handleUpload(fetcher.data, uploadingIndex);
    }
  }, [fetcher]);

  const handleItemChange = (
    index: number,
    field: "text" | "secondaryText" | "image",
    value: string | null
  ) => {
    const updatedItems = [...listItems];
    if (value === null && field === "image") {
      // Remove the image property entirely
      const { image, ...rest } = updatedItems[index];
      updatedItems[index] = rest as ListItem;
    } else {
      updatedItems[index] = { ...updatedItems[index], [field]: value };
    }
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadingIndex(index);
      setUploadProgress(0);

      const formData = new FormData();
      formData.append("filename", file.name);
      formData.append("contentType", file.type);
      formData.append("fileSize", file.size.toString());
      formData.append("uploadType", "image");

      if (editorState.videoId) {
        formData.append("videoId", editorState.videoId.toString());
      }

      fetcher.submit(formData, { method: "post", action: "/upload" });
    }
  };

  const handleUpload = async (data: any, index: number) => {
    if (data.success) {
      const file = fileInputRef.current?.files?.[0];
      if (file) {
        await fetch(data.signedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        const totalSteps = 100;
        for (let i = 0; i <= totalSteps; i++) {
          setUploadProgress(i);
          await new Promise((resolve) => setTimeout(resolve, 20));
        }

        handleItemChange(index, "image", data.publicUrl);
      }
    } else {
      console.error("Failed to get signed URL");
    }

    setUploadingIndex(null);
    setUploadProgress(0);
  };

  const handleAddItem = () => {
    setListItems([...listItems, { text: "", secondaryText: "" }]);
    handleContentChange([...listItems, { text: "", secondaryText: "" }]);
  };

  const handleRemoveItem = (index: number) => {
    const updatedItems = listItems.filter((_, i) => i !== index);
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleMoveItem = (index: number, direction: "up" | "down") => {
    const updatedItems = [...listItems];
    if (direction === "up" && index > 0) {
      [updatedItems[index - 1], updatedItems[index]] = [
        updatedItems[index],
        updatedItems[index - 1],
      ];
    } else if (direction === "down" && index < updatedItems.length - 1) {
      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
    }
    setListItems(updatedItems);
    handleContentChange(updatedItems);
  };

  const handleRemoveImage = (index: number, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent triggering the file upload dialog
    handleItemChange(index, "image", null);
  };

  const initiateUpload = (index: number) => {
    if (fileInputRef.current) {
      fileInputRef.current.setAttribute('data-index', index.toString());
      fileInputRef.current.click();
    }
  };

  // we have lists of 4 types:
  // 1. just text
  // 2. text + secondary text
  // 3. text + image
  // 4. text + secondary text + image
  // let's find out which types of content we can edit here
  let canEditText = false;
  let canEditSecondaryText = false;
  let canEditImages = false;
  
  // the first item determines the structure for all items in the list
  let firstItem = listItems[0];
  if (firstItem && "text" in firstItem) {
    canEditText = true;
  }
  if (firstItem && "secondaryText" in firstItem) {
    canEditSecondaryText = true;
  }
  if (firstItem && "image" in firstItem) {
    canEditImages = true;
  }

  return (
    <Popover
      opened={!isEditable ? false : isOpen}
      onChange={setIsOpen}
      width={400}
      position="left"
      offset={20}
      withArrow
      transitionProps={{ duration: 0 }}
    >
      <Popover.Target>
        <Box
          title={isEditable ? t("Edit list") : ""}
          className="frame__editable_content"
          onClick={() => setIsOpen((o) => !o)}
          style={{ ...style }}
        >
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <ScrollArea.Autosize offsetScrollbars mah={800}>
          {hint && (
            <Text size="sm" c="dimmed" mb="xs">
              {hint}
            </Text>
          )}
          {listItems.map((item, index) => (
            // bigger margin bottom if additional elements besides text
            <Box key={index} mb={(canEditImages || canEditSecondaryText) ? 'xl' : 'sm'}>
              <Group align="flex-start" wrap="nowrap">
                <Stack style={{ flex: 1 }} gap="xs">
                  {canEditText && (
                    <TextInput
                      classNames={{ input: "frame__editable_content_text_input" }}
                      rightSection={
                        <ActionIcon.Group mr={"xl"}>
                          <ActionIcon
                            variant="transparent"
                            color="gray"
                            onClick={() => handleMoveItem(index, "up")}
                            disabled={index === 0}
                            title={t("Move up")}
                          >
                            <IconArrowUp size="1rem" />
                          </ActionIcon>
                          <ActionIcon
                            variant="transparent"
                            color="gray"
                            onClick={() => handleMoveItem(index, "down")}
                            disabled={index === listItems.length - 1}
                            title={t("Move down")}
                          >
                            <IconArrowDown size="1rem" />
                          </ActionIcon>
                        </ActionIcon.Group>
                      }
                      placeholder={t("Enter main text")}
                      value={item.text}
                      onChange={(e) =>
                        handleItemChange(index, "text", e.target.value)
                      }
                    />
                  )}
                  {canEditSecondaryText && (
                    <TextInput
                      classNames={{ input: "frame__editable_content_text_input" }}
                      placeholder={t("Enter secondary text")}
                      value={item.secondaryText}
                      onChange={(e) =>
                        handleItemChange(index, "secondaryText", e.target.value)
                      }
                    />
                  )}
                  {uploadingIndex === index && (
                    <Progress value={uploadProgress} size="sm" />
                  )}
                </Stack>
                <Group>
                  <ActionIcon
                    variant="light"
                    color="red"
                    onClick={() => handleRemoveItem(index)}
                    title={t("Remove item")}
                  >
                    <IconTrash size="1rem" />
                  </ActionIcon>
                </Group>
              </Group>
              {canEditImages && 
                (<>{item.image ? (
                  <Box 
                    mt="xs"
                    style={{ position: 'relative' }}
                    onMouseEnter={() => setHoveredImageIndex(index)}
                    onMouseLeave={() => setHoveredImageIndex(null)}
                  >
                    <Paper
                      p="sm"
                      style={{ 
                        cursor: 'pointer',
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '4px',
                        textAlign: "center"
                      }}
                      onClick={() => initiateUpload(index)}
                    >
                      <img 
                        src={item.image} 
                        alt={item.text} 
                        style={{ 
                          // width: '100%',
                          // height: 'auto',
                          display: 'block',
                          margin: "auto",
                          maxHeight: "50px",
                          // fontSize: "0"
                        }} 
                      />
                      {hoveredImageIndex === index && (
                        <>
                          <Box
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: 'rgba(0, 0, 0, 0.4)',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <IconUpload size="2rem" color="white" />
                          </Box>
                          <ActionIcon
                            variant="light"
                            color="red"
                            // size="sm"
                            style={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              zIndex: 2
                            }}
                            onClick={(e) => handleRemoveImage(index, e)}
                            title={t("Remove image")}
                          >
                            <IconX size="1rem" />
                          </ActionIcon>
                        </>
                      )}
                    </Paper>
                  </Box>
                ) : <Button onClick={() => initiateUpload(index)} leftSection={<IconUpload size="1em"></IconUpload>} pt="xs" variant="transparent" size="compact-sm">Add image</Button>}
                </>)
              }
            </Box>
          ))}
          <Button
            variant="light"
            leftSection={<IconPlus size="1rem" />}
            onClick={handleAddItem}
            fullWidth
            mt="md"
          >
            {t("Add item")}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={(e) => {
              const index = parseInt(fileInputRef.current?.getAttribute('data-index') || '0', 10);
              handleFileChange(e, index);
            }}
          />
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
}