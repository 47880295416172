import React, { useMemo, useEffect, useState } from "react";
import { Img, interpolate, spring } from "remotion";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { calculateFontSize } from "../../../remotionHelpers/calculateFontSize";
import { FPS } from "../../../remotionConstant";

export const USPtitle = ({
  projectName,
  logoPath,
  currentRemotionFrame,
  durationInFrames,
  displayAnimations,
  isEditable,
  commonStyles,
}) => {
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.src = logoPath.value;
    img.onload = () => {
      setOriginalWidth(img.width);
      setOriginalHeight(img.height);
    };
  }, [logoPath]);

  const progress = spring({
    fps: FPS,
    frame: currentRemotionFrame,
    config: {
      damping: 200,
    },
    durationInFrames: 100,
  });

  const rightStop = interpolate(progress, [0, 1], [200, 0]);
  const leftStop = Math.max(0, rightStop - 60);
  const maskImage = `linear-gradient(-45deg, transparent ${leftStop}%, black ${rightStop}%)`;
  const opacity = interpolate(currentRemotionFrame, [0, 30], [0, 1]);

  const containerStyle = useMemo(
    () => ({
      display: "flex",
      alignItems: "center",
      opacity: displayAnimations ? opacity : 1,
      maskImage: displayAnimations ? maskImage : undefined,
      WebkitMaskImage: displayAnimations ? maskImage : undefined,
    }),
    [displayAnimations, opacity, maskImage]
  );

  const logoStyle = useMemo(
    () => ({
      height: "10em",
      marginRight: "0.5em",
    }),
    []
  );

  const fontSize = calculateFontSize(
    projectName?.value.length || 0,
    [12, 22, 35, 60, 75], // referenceLengths
    [4, 3.5, 3, 2.5, 2] // fontSizes (max to min)
  );

  const titleStyle = useMemo(
    () => ({
      ...commonStyles,
      fontSize: `${fontSize}em`,
      fontWeight: 600,
      letterSpacing: "0.02em",
      marginLeft: "0.6em"
    }),
    [commonStyles, fontSize]
  );

  const showImage = () => (
    <Img src={logoPath.value} style={logoStyle} />
  );

  const showText = () => (
    <h1 style={titleStyle}>
      {projectName?.value || "Default Project Name"}
    </h1>
  );

  return (
    <div style={containerStyle}>
      {isEditable ? (
        <Editable isEditable={isEditable} content={logoPath} hint={"Your logo. Square works the best."}>
          {showImage()}
        </Editable>
      ) : (
        showImage()
      )}
      
      {isEditable ? (
        <Editable isEditable={isEditable} content={projectName} hint={"Your project's name."}>
          {showText()}
        </Editable>
      ) : (
        showText()
      )}
    </div>
  );
};