import React from "react";
import {
  Img,
  interpolate,
  spring,
} from "remotion";
import { isColorDark } from "../../../remotionHelpers/isColorDark";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { FPS } from "../../../remotionConstant";

export const SingleTestimonial = ({
  currentRemotionFrame,
  content,
  displayAnimations,
  isEditable,
  commonStyles,
  bgColorObject,
  editorState,
}) => {
  const frame = currentRemotionFrame;
  const typingDuration = 60;
  const bubbleExpandDuration = 15;
  const textAppearDuration = 15;

  let textColorCss = {
    ...bgColorObject["textCss"],
  };

  const accentColor = editorState.styles.accentColor;

  const bubbleExpandProgress = spring({
    fps: FPS,
    frame: frame - typingDuration,
    config: { damping: 20 },
    durationInFrames: bubbleExpandDuration,
  });

  const textAppearProgress = spring({
    fps: FPS,
    frame: frame - typingDuration - bubbleExpandDuration,
    config: { damping: 20 },
    durationInFrames: textAppearDuration,
  });

  const bubbleScale = interpolate(bubbleExpandProgress, [0, 1], [0, 1]);
  const textOpacity = interpolate(textAppearProgress, [0, 1], [0, 1]);

  const getDotTranslateY = (index: number) => {
    const jumpDuration = 20;
    const jumpHeight = 6;
    const delay = index * 6;
    const jumpProgress = (frame - delay) % jumpDuration;
    const jumpFactor = Math.sin((jumpProgress / jumpDuration) * Math.PI);
    return -jumpFactor * jumpHeight;
  };

  const showImage = () => (
    <Img
      src={content.image1.value}
      style={{
        display: "block",
        overflow: "hidden",
        height: "9.5em",
        width: "9.5em",
        borderRadius: "50%",
      }}
    />
  );
  const showName = () => (
    <div
      style={{
        ...textColorCss,
        opacity: 0.5,
        fontSize: "2.5em",
        marginBottom: "0.25em",
        marginLeft: "0.5em",
      }}
    >
      {content.text1.value}
    </div>
  );
  const showTestimonial = () => (
    <p
      style={{
        margin: 0,
        fontSize: "2.5em",
        textAlign: "left",
        opacity: displayAnimations ? textOpacity : 1,
        lineHeight: "1.5",
        color: "black"
      }}
    >
      {content.text2.value}
    </p>
  );

  const renderContent = () => {
    if (!displayAnimations) {
      // Static preview
      return (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "1em",
            padding: "1.5em 1.4em 2.2em 2.3em",
            boxShadow: `-1em 1em 0 ${accentColor}`,
            maxWidth: "62em",
            border: "0.3em solid black",
          }}
        >
          {isEditable ? (
            <Editable
              isEditable={isEditable}
              content={content.text2}
              hint={"Testimonial text. 1-2 paragraphs."}
            >
              {showTestimonial()}
            </Editable>
          ) : (
            showTestimonial()
          )}
        </div>
      );
    }

    // Animated version
    return (
      <>
        {frame < typingDuration ? (
          <div
            style={{
              backgroundColor: accentColor,
              borderRadius: "1.4em",
              padding: "1em",
              height: "2.8em",
              display: "flex",
              marginTop: "1.2em",
              marginLeft: "0.8em",
              width: "9.2em",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {[0, 1, 2].map((index) => (
              <div
                key={index}
                style={{
                  width: "1em",
                  height: "1em",
                  borderRadius: "50%",
                  backgroundColor: isColorDark(accentColor) ? "white" : "black",
                  marginRight: "0.5em",
                  marginLeft: "0.5em",
                  transform: `translateY(${(getDotTranslateY(index) + 2)/16}em)`,
                }}
              />
            ))}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "1em",
              padding: "1.5em 1.4em 2.2em 2.3em",
              boxShadow: `-1em 1em 0 ${accentColor}`,
              maxWidth: "62em",
              transform: `scale(${bubbleScale})`,
              transformOrigin: "top left",
              opacity: bubbleExpandProgress,
              border: "0.3em solid black",
            }}
          >
            {isEditable ? (
              <Editable
                isEditable={isEditable}
                content={content.text2}
                hint={"Testimonial text. 1-2 paragraphs."}
              >
                {showTestimonial()}
              </Editable>
            ) : (
              showTestimonial()
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        marginTop: "31em",
        marginLeft: "16.8em",
        position: "relative",
        zIndex: 1000
      }}
    >
      <div
        style={{
          position: "relative",
          marginRight: "2em",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            top: "0.4em",
            right: "0.8em",
            zIndex: 100,
            width: "1.2em",
            height: "1.2em",
            borderRadius: "50%",
            backgroundColor: "#00d061",
            border: "0.15em solid white",
            position: "absolute",
          }}
        ></div>
        {isEditable ? (
          <Editable
            isEditable={isEditable}
            content={content.image1}
            hint={"Testimonial author's photo."}
          >
            {showImage()}
          </Editable>
        ) : (
          showImage()
        )}
      </div>
      <div>
        {isEditable ? (
          <Editable
            isEditable={isEditable}
            content={content.text1}
            hint={"Testimonial author's name."}
          >
            {showName()}
          </Editable>
        ) : (
          showName()
        )}
        {renderContent()}
      </div>
    </div>
  );
};