const assets = {
    paracastLogoTransparent: "https://public-assets.paracast.io/paracast_logo.svg",
    badgeWrapper: "https://public-assets.paracast.io/paracast_badge_wrapper.svg",
    pointerIcon: "https://public-assets.paracast.io/icons8-pointer.svg",
    confettiVideo: "https://public-assets.paracast.io/handy-red-confetti.webm"
  }
  
  export function getAssetPath(
    routeName: keyof typeof assets,
  ): string {
    let path = assets[routeName];
  
    return path;
  }