import { Button } from "@mantine/core";
import { IconMessageDots } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export function HelpButton() {
  let { t } = useTranslation();
  // this can be a link to help center or a button that opens help chat
  return (
    <Button
      variant="default"
      size="xs"
      leftSection={<IconMessageDots size="1rem" />}
      title={t("toggle_help_chat_hint")}
      component={"a"}
      href="mailto:hi@paracast.io"
      target="_blank"
    >
      {t("get_help")}
    </Button>
  );
}
