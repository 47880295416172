import React, { useEffect, useRef } from "react";
import { AbsoluteFill, spring, interpolate } from "remotion";
import { easeInOutExpo, FPS } from "../../../remotionConstant";
import { isColorDark } from "../../../remotionHelpers/isColorDark";
import { calculateFontSize } from "../../../remotionHelpers/calculateFontSize";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";

export const Audiences = ({
  currentRemotionFrame,
  content,
  displayAnimations,
  isEditable,
  bgColorObject,
  editorState,
  commonStyles
}) => {
  const frame = currentRemotionFrame;
  const typeTextRef = useRef<HTMLDivElement>(null);

  const audiences = content.list1?.value.map(item => item.text) || ["Startups", "Makers", "Founders", "Creators", "Marketers"];
  const wordDuration = 30;
  const highlightFrames = 4;

  const accentColor = editorState.styles.accentColor;
  const googleFontName = editorState.styles.googleFontName;

  let textColorCss = {
    ...bgColorObject["textCss"],
  };

  const longAudience = audiences.some((audience) => audience.length > 14);
  const fontSize = longAudience ? 140 : 160;

  useEffect(() => {
    const textToBeTypedIndex =
      Math.floor(frame / (wordDuration * 2)) % audiences.length;
    const textToBeTyped = audiences[textToBeTypedIndex];
    const isAdding = frame % (wordDuration * 2) < wordDuration;
    const index = Math.min(
      isAdding ? frame % wordDuration : wordDuration,
      textToBeTyped.length
    );

    if (typeTextRef.current) {
      if (isAdding) {
        typeTextRef.current.innerText = textToBeTyped.slice(0, index);
        typeTextRef.current.style.backgroundColor = "transparent";
        typeTextRef.current.style.color = bgColorObject["isDark"]
          ? "white"
          : "black";
      } else {
        typeTextRef.current.innerText = textToBeTyped;
        const deleteFrame = wordDuration * 2 - highlightFrames;
        if (
          frame % (wordDuration * 2) >= deleteFrame &&
          frame % (wordDuration * 2) < deleteFrame + highlightFrames
        ) {
          typeTextRef.current.style.backgroundColor = accentColor;
          typeTextRef.current.style.color = isColorDark(accentColor)
            ? "white"
            : "black";
        } else {
          typeTextRef.current.style.backgroundColor = "transparent";
          typeTextRef.current.style.color = bgColorObject["isDark"]
            ? "white"
            : "black";
        }
      }
    }
  }, [frame, audiences, accentColor, bgColorObject]);

  const showCursor = displayAnimations && (frame % FPS < 15);

  const containerTransform = displayAnimations
    ? spring({
        frame,
        fps: 30,
        from: 0,
        to: -250,
        config: {
          damping: 100,
          stiffness: 200,
        },
      })
    : -250;

  const showHeadline = () => (
    <div
      style={{
        fontSize: "6.9em",
        ...textColorCss,
        fontWeight: "600",
        // fontFamily: googleFontName,
        marginBottom: "-0.2em",
        display: "flex"
      }}
    >
      <Editable
        isEditable={isEditable}
        content={content.text3}
        hint={"First part of the headline"}
      >
        {content.text3.value || "Made"}
      </Editable>{" "}
      <span style={{ marginLeft: "0.3em", fontStyle: "italic" }}>
        <Editable
          isEditable={isEditable}
          content={content.text4}
          hint={"Second part of the headline (italicized)"}
        >
          {content.text4.value || "exclusively"}
        </Editable>
      </span>
    </div>
  );

  const showAudiences = () => (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <div
        style={{
          fontSize: "6.9em",
          ...textColorCss,
          fontWeight: "600",
          marginRight: "0.3em"
          // fontFamily: googleFontName,
        }}
      >
        <Editable
          isEditable={isEditable}
          content={content.text5}
          hint={"Text before the audience list"}
        >
          {/* for the timeline preview, we simply display ad additional word (audiences[0]}) instead of adapting the whole audiences block. */}
          {content.text5.value || "for"} {!displayAnimations && (<b>{audiences[0]}</b>)}
        </Editable>{" "}
      </div>

      {/* this big block with changing audiences is only needed for the video (when displayAnimations==true) but not for the timeline preview */}
      {displayAnimations && <div style={{ height: "160px", position: "relative" }}>
        <div
          ref={typeTextRef}
          className={showCursor ? "typeText typeText--with-cursor" : "typeText"}
          style={{
            position: "absolute",
            fontSize: `${fontSize}px`,
            lineHeight: `${fontSize}px`,
            height: `${fontSize}px`,
            ...textColorCss,
            // fontFamily: googleFontName,
            fontWeight: 800,
            letterSpacing: "0.01em",
            backgroundBlendMode: "color-dodge",
            whiteSpace: "nowrap",
          }}
        />
        <Editable
            isEditable={isEditable}
            content={content.list1}
            hint={"List of audiences, 3-4 items."}
          >
            <div
              style={{
                fontSize: `${fontSize}px`,
                lineHeight: `${fontSize}px`,
                ...textColorCss,
                // fontFamily: googleFontName,
                fontWeight: 800,
                letterSpacing: "0.01em",
                visibility: "hidden",
              }}
            >
            {audiences[0]}
          </div>
        </Editable>
      </div>}
    </div>
  );

  return (
    <AbsoluteFill
      style={{
        zIndex: 10,
      }}
    >
      <style>
        {`
          .typeText--with-cursor:after {
            content: "";
            position: absolute;
            right: -14px;
            top: 0;
            display: inline-block;
            width: 10px;
            height: 180px;
            vertical-align: bottom;
            background: ${bgColorObject["isDark"] ? "white" : "black"};
            border-bottom: 6px solid ${accentColor};
          }

          .typeText--with-cursor:after.state-hidden {
            opacity: 0;
          }
        `}
      </style>
      <div
        style={{
          textAlign: "left",
          maxWidth: "50em",
          position: "relative",
          left: `${276/16}em`,
          top: `${350/16}em`,
          transform: `translateY(${containerTransform/16}em)`,
        }}
      >
        {showHeadline()}
 
        {showAudiences()}
      </div>
    </AbsoluteFill>
  );
};